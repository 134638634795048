<template>
  <dialog-outline v-model="showDialog">
    {{ dialogTitle }}
    <template v-slot:icon-name>
      {{ dialogIcon }}
    </template>

    <!-- Close Dialog icon -->
    <template v-slot:icon-cross>
      <base-tooltip-button @click="closeDialog">
        <template v-slot:icon-name>
          {{ iconCloseDialog }}
        </template>
        <template v-slot:toolTip>
          {{ closeDialogTooltip }}
        </template>
      </base-tooltip-button>
    </template>

    <template v-slot:pre-context>
      <!-- display error -->
      <base-alert
        v-model="showAlert"
        :dismissible="isAlertDismissible"
        :type="currentAlertType"
      >
        <span v-html="alertMessage"></span>
      </base-alert>
    </template>

    <!-- context -->
    <template v-slot:context>
      <!-- record details banner, which represents a subject that current operation will be performed -->
      <record-banner :record="record"></record-banner>

      <div class="mt-5">
        <v-radio-group v-model="option">
          <v-radio :value="exportAnnotationOptions.customPage">
            <template v-slot:label>
              {{ exportLabel }}
              <div style="width: 200px">
                <v-select
                  class="ml-5"
                  v-model="selectedPage"
                  :items="totalPages"
                  :label="labelPage"
                  :prepend-icon="iconPage"
                ></v-select>
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </div>

      <!-- display Progress -->
      <progress-display v-if="visibleProgress">
        {{ progressText }}
      </progress-display>
    </template>

    <!-- Dialog Actions -->
    <template v-slot:actions>
      <base-button @click="onOk">
        {{ dialogOkCommandLabel }}
      </base-button>
    </template>

    <!-- Cancel Action -->
    <template v-slot:actionCancel>
      <base-button @click.stop="closeDialog()">
        <template v-slot:default>
          {{ dialogCancelCommandLabel }}
        </template>
      </base-button>
    </template>
  </dialog-outline>
</template>

<script>
// design
import { iconExport, iconPage } from "@/design/icon/iconConst";

// model
import {
  findImageOperation,
  imageOperation,
  exportAnnotationOptions
} from "@/model/image/imageModel";

// mixins
import { dialogOutlineMixin } from "@/mixins/shared/base/dialog/dialogOutlineMixin";
import { baseComponentMixin } from "@/mixins/shared/base/component/baseComponentMixin";
import { recordBannerAble } from "@/mixins/shared/bannerAble/recordBannerAble";

//model
import { actionResultType } from "@/model/action/actionModel";
import { fullRecordName } from "@/services/record/recordService";
import { progressAbleMixin } from "@/mixins/shared/progressAble/progressAbleMixin";

export default {
  name: "ExportAnnotationDialog",
  mixins: [
    dialogOutlineMixin,
    baseComponentMixin,
    recordBannerAble,
    progressAbleMixin
  ],
  props: {
    /**
     * @type {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[], operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}}
     */
    record: undefined,
    totalPages: {
      type: Array,
      required: true
    },
    exportEvent: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      iconExport: iconExport,
      iconPage: iconPage,
      option: exportAnnotationOptions.customPage,
      page: undefined,
      exportAnnotationOptions: exportAnnotationOptions
    };
  },

  computed: {
    /**
     * Selected Page Value
     */
    selectedPage: {
      get() {
        return this.page ?? this.totalPages[0];
      },
      set(value) {
        this.page = value;
      }
    },
    /**
     * Export Annotation Operation
     * @return {{name: string, icon: string, label: string}} Export Annotation Operation
     */
    operation() {
      return findImageOperation(imageOperation.ExportAnnotation);
    },

    /**
     * Export Annotation operation Label
     * @return {string} Export Annotation operation Label
     */
    operationLabel() {
      return this.operation?.label;
    },

    /**
     * dialog Icon
     * @return {string} Icon name
     */
    dialogIcon() {
      return this.operation?.icon;
    },

    /**
     * Get Export Annotation Dialog title
     * @return {string} Export Dialog title
     */
    dialogTitle() {
      return `${this.operationLabel}`;
    },

    /**
     * dialog Ok Command Label
     * remarks: overwrite modalDialogMixin.dialogOkCommandLabel
     * @return {string} OK command Label
     */
    dialogOkCommandLabel() {
      return "Export";
    },

    /**
     * label Select Page
     * @return {string} label Source Page
     */
    labelPage() {
      return "Select Page";
    },

    /**
     * Dialog action
     * @return {string}
     */
    action() {
      return `${this.operationLabel} ${
        this.record?.recordType
      }: '${fullRecordName(this.record)}'`;
    },

    /**
     * Export Progress Text
     * @return {string}
     */
    exportProgressText() {
      return `Preparing to Export Annotation File`;
    },

    /**
     * Export Option Label
     * @return {string}
     */
    exportLabel() {
      return "Export from";
    }
  },

  methods: {
    /**
     * Performs Export Annotation
     */
    async onOk() {
      try {
        this.clearAlert();
        this.showProgress(this.formatProgressText(this.exportProgressText));
        const pageOption = this.getExportPageOption();
        const result = await this.exportEvent(pageOption);
        if (result?.type === actionResultType.success) {
          await this.autoCloseDialog(result.message);
        } else if (result?.type === actionResultType.abort) {
          this.closeDialog();
        } else {
          this.internalAlert = this.createAlertError(
            this.formatAlertError(this.action, result.message),
            false
          );
        }
      } catch (e) {
        this.internalAlert = this.createAlertError(
          this.formatAlertError(this.action, e),
          false
        );
        this.closeProgress();
      } finally {
        this.closeProgress();
      }
    },

    getExportPageOption() {
      return this.option === exportAnnotationOptions.customPage ? this.page : 0;
    }
  }
};
</script>
