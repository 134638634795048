/**
 * progress-able Mixin used to display progress using Progress Display component
 * @type {{components: {ProgressDisplay: (function(): Promise<*>)}, props: {visibleProgress: {default: boolean, type: BooleanConstructor}, uploadFileProgressText: {default: string, type: StringConstructor}}}}
 */
export const progressAbleMixin = {
  components: {
    ProgressDisplay: () =>
      import("@/components/shared/core/progress/ProgressDisplay")
  },
  data() {
    return {
      /**
       * indicate whether Progress is visible
       * @type {Boolean}
       */
      visibleProgress: false,

      /**
       * Progress text to be displayed
       * @type {string}
       */
      progressText: undefined
    };
  },
  methods: {
    formatProgressText(action) {
      return `${action} ...`;
    },
    showProgress(text) {
      this.progressText = text;
      this.visibleProgress = true;
    },
    closeProgress() {
      this.progressText = undefined;
      this.visibleProgress = false;
    }
  }
};
