import { fullRecordVersionName } from "@/services/record/recordService";

/**
 * record Banner Able Mixin encapsulate record - Banner functionality
 */
export const recordBannerAble = {
  components: {
    RecordBanner: () => import("@/components/record/RecordBanner")
  },
  methods: {
    bannerTitle(record) {
      return fullRecordVersionName(record);
    }
  }
};
